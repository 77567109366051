import React from "react";

import Layout from "../../components/Layout";

import * as Constants from "../../lib/constants";
import { addSlash } from "../../lib/helpers";

import * as styles from "./Terms.module.css";

const TermsContainer = ({ path }) => {
  return (
    <Layout
      metaDesc={"Tobo Apps Terms and Conditions"}
      title="Terms and Conditions"
      canonicalPath={addSlash(path)}
      ogUrlPath={addSlash(path)}
    >
      <main className={styles.container}>
        <h1>Terms &amp; Conditions</h1>
        <p>Egemen Can Üze built the Tobo language apps as Ad Supported apps.</p>
        <p>This Service is provided by Tobo and is intended for use as is.</p>
        <p>
          By downloading or using the app, these terms will automatically apply to you – you should
          make sure therefore that you read them carefully before using the app. You’re not allowed
          to copy, or modify the app, any part of the app, or our trademarks in any way. You’re not
          allowed to attempt to extract the source code of the app, and you also shouldn’t try to
          translate the app into other languages, or make derivative versions. The app itself, and
          all the trade marks, copyright, database rights and other intellectual property rights
          related to it, still belong to
          {Constants.APP_NAME}.
        </p>
        <p>
          {Constants.APP_NAME} is committed to ensuring that the app is as useful and efficient as
          possible. For that reason, we reserve the right to make changes to the app or to charge
          for its services, at any time and for any reason. We will never charge you for the app or
          its services without making it very clear to you exactly what you’re paying for.
        </p>
        <p>
          The {Constants.APP_NAME} stores and processes personal data that you have provided to us,
          in order to provide our Service. It’s your responsibility to keep your phone and access to
          the app secure. We therefore recommend that you do not jailbreak or root your phone, which
          is the process of removing software restrictions and limitations imposed by the official
          operating system of your device. It could make your phone vulnerable to
          malware/viruses/malicious programs, compromise your phone’s security features and it could
          mean that the {Constants.APP_NAME} won’t work properly or at all.
        </p>
        <p>
          You should be aware that there are certain things that {Constants.APP_NAME} will not take
          responsibility for. Certain functions of the app will require the app to have an active
          internet connection. The connection can be Wi-Fi, or provided by your mobile network
          provider, but {Constants.APP_NAME} cannot take responsibility for the app not working at
          full functionality if you don’t have access to Wi-Fi, and you don’t have any of your data
          allowance left.
        </p>
        <p>
          If you’re using the app outside of an area with Wi-Fi, you should remember that your terms
          of the agreement with your mobile network provider will still apply. As a result, you may
          be charged by your mobile provider for the cost of data for the duration of the connection
          while accessing the app, or other third party charges. In using the app, you’re accepting
          responsibility for any such charges, including roaming data charges if you use the app
          outside of your home territory (i.e. region or country) without turning off data roaming.
          If you are not the bill payer for the device on which you’re using the app, please be
          aware that we assume that you have received permission from the bill payer for using the
          app.
        </p>
        <p>
          Along the same lines, {Constants.APP_NAME} cannot always take responsibility for the way
          you use the app i.e. You need to make sure that your device stays charged – if it runs out
          of battery and you can’t turn it on to avail the Service, {Constants.APP_NAME} cannot
          accept responsibility.
        </p>
        <p>
          With respect to {Constants.APP_NAME}’s responsibility for your use of the app, when you’re
          using the app, it’s important to bear in mind that although we endeavour to ensure that it
          is updated and correct at all times, we do rely on third parties to provide information to
          us so that we can make it available to you. {Constants.APP_NAME} accepts no liability for
          any loss, direct or indirect, you experience as a result of relying wholly on this
          functionality of the app.
        </p>
        <p>
          At some point, we may wish to update the app. The app is currently available on Android
          and iOS – the requirements for system(and for any additional systems we decide to extend
          the availability of the app to) may change, and you’ll need to download the updates if you
          want to keep using the app. {Constants.APP_NAME} does not promise that it will always
          update the app so that it is relevant to you and/or works with the Android or iOS version
          that you have installed on your device. However, you promise to always accept updates to
          the application when offered to you, We may also wish to stop providing the app, and may
          terminate use of it at any time without giving notice of termination to you. Unless we
          tell you otherwise, upon any termination, (a) the rights and licenses granted to you in
          these terms will end; (b) you must stop using the app, and (if needed) delete it from your
          device.
        </p>
        <h3>Account</h3>
        <p>
          To be able to use Premium features and if you decide to upgrade to Premium, you can
          register for an account by providing a valid email address. Any personal information you
          submit to us when creating a user account is subject to our Privacy Policies. You are
          responsible for the confidentiality of your account credentials and you are responsible
          for the activities under your account. Your Account must be used only by you and must not
          be shared with, or transferred to any other individual. You must immediately notify us via
          our support address of any suspected or actual, unauthorized or fraudulent use of your
          Account or any other breach of security.
        </p>
        <p>
          We reserve the right to modify, temporarily suspend or permanently delete your account and
          terminate your access to the Services if you have breached the Terms of using our
          services.
        </p>
        <p>
          You can request deletion of your account by sending a request to
          {" " + Constants.CONTACT_EMAIL}
        </p>
        <h3>Subscriptions</h3>
        <p>
          The freemium version of the App Content can be consumed free of charge. The user can
          upgrade to the “Premium” version with monthly, quarterly or yearly subscriptions to unlock
          advanced premium features. Subscription fees are subject to different subscription plans
          and we reserve the right to update our fees from time to time.
        </p>
        <p>
          Some of the apps we provide offer subscriptions to access Premium features in newer
          versions.
        </p>
        <p>
          Please note that the following conditions apply for renewals of monthly, quarterly and
          yearly Premium subscription if you have purchased the subscription through Apple iTunes or
          iPhone/iPad application:
        </p>
        <p>
          Payment will be charged to iTunes Account at confirmation of purchase. Subscription
          automatically renews unless auto-renew is turned off at least 24-hours before the end of
          the current period. Account will be charged for renewal within 24-hours prior to the end
          of the current period, and identify the cost of the renewal. Subscriptions may be managed
          by the user and auto-renewal may be turned off by going to the user's Account Settings
          after purchase.
        </p>
        <p>
          The following conditions apply for renewals of monthly, quarterly and yearly Premium
          subscription if you have purchased the subscription through Google Play:
        </p>
        <p>
          Subscriptions on Google Play renew automatically unless you unsubscribe. If you don't want
          the subscription to renew, you can cancel the subscription on Google Play.
        </p>
        <h3>Changes to This Terms and Conditions</h3>
        <p>
          We may update our Terms and Conditions from time to time. Thus, you are advised to review
          this page periodically for any changes. We will notify you of any changes by posting the
          new Terms and Conditions on this page. These changes are effective immediately after they
          are posted on this page.
        </p>
        <h3>Contact Us</h3>
        <p>
          If you have any questions or suggestions about our Terms and Conditions, do not hesitate
          to contact us. ({Constants.CONTACT_EMAIL})
        </p>
      </main>
    </Layout>
  );
};

export default TermsContainer;
